<template>
  <div class="dynamic">
    <div class="dynamic-header">协会风采</div>
    <div class="dynamic-title">
      <div></div>
      新闻动态
    </div>
    <div class="dynamic-main">
      <div class="main-block" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
        <img :src="item.title_image" alt="">
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
    <div class="ViewMore" v-if="isShowMore">
      <div @click="goPage">查看更多</div>
      <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/a6b4f82ddb234df9afb14fdbff0e7aa9.png" alt="">
    </div>
  </div>
</template>

<script>
import { getArticleList } from '@/api/admin'
export default {
  name: 'information',
  data () {
    return {
      tabKey: '0',
      isShowMore: true,
      list: [],
      reqParams: {
        page: 1,
        per_page: 6,
        search_data: '', // 关键字搜索
        advisory_type: '1', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '0', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取数据
    async getList (type) {
      if (type !== 'paging') {
        this.reqParams.page = 1
      }
      const res = await getArticleList(this.reqParams)
      console.log('视频数据', res)
      if (res.list.length < this.reqParams.per_page) {
        this.isShowMore = false
      }
      if (type !== 'paging') {
        this.list = res.list
      } else {
        const list = this.list
        this.list = list.concat(res.list)
      }
    },
    // 去详情
    goDetail (item) {
      console.log(item)
      this.$router.push({
        path: 'video',
        query: {
          title: item.title,
          advisory_type: item.advisory_type,
          type: 'video'
        }
      })
      // this.$router.push({
      //   name: 'newsDetail',
      //   query: {
      //     ...item,
      //     type: 'video'
      //   }
      // })
    },
    // 点击下一页
    goPage () {
      this.reqParams.page++
      this.getList('paging')
    }
  }
}
</script>

<style scoped lang="scss">
.dynamic {
  margin-top: 68px;
  .dynamic-header{
    width: 1200px;
    margin: 0 auto;
    font-size: 32px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    text-align: CENTER;
    color: #000000;
    line-height: 38px;
    padding-top: 39px;
    padding-bottom: 26px;
  }
  .dynamic-title{
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-400;
    font-weight: 400;
    color: #000000;
    height: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
    padding-bottom: 13px;
    border-bottom: 1px solid #d9d9d9;
    div{
      margin-right: 7px;
      width: 12px;
      height: 22px;
      background: #0c855a;
    }
  }
  .dynamic-main{
    width: 1200px;
    margin: 0 auto;
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    .main-block{
      width: 387px;
      height: 374px;
      background: #ffffff;
      border-radius: 5px;
      box-shadow: 0px 0px 15.5px 4px rgba(51,51,51,0.04);
      margin-right: 18px;
      margin-bottom: 61px;
      cursor: pointer;
      img{
        width: 100%;
        height: 246px;
      }
      div{
        margin-top: 19px;
        padding: 0 30px 30px 30px;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
        line-height: 24px;
      }
    }
    .main-block:nth-child(3n){
      margin-right: 0px;
    }
  }
  .ViewMore{
    width: 1200px;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 85px;
    cursor: pointer;
    div{
      text-align: center;
      margin-top: 48px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
    .img{
      width: 16px;
      height: 16px;
    }
  }
}
</style>
<style scoped lang="scss">
@media all and (max-width: 1200px) {
  .dynamic-header,.dynamic-title,.dynamic-main{
    width: 100% !important;
  }
  .main-block:nth-child(3n){
    margin-right: 18px !important;
    }
  .dynamic .dynamic-main .main-block{
    margin-bottom: 18px !important;
  }
  .ViewMore{
    width: 100% !important;
  }
}
@media all and (max-width: 768px) {
  .dynamic{
    margin-top: 50px !important;
  }
  .dynamic-header{
    font-size: 24px !important;
    padding: 23px 0 22px 0 !important;
    font-weight: 700 !important;
  }
  .dynamic-main{
    // justify-content: space-evenly;
    width: 360px  !important;
    .block_block{
      width: 360px  !important;
      margin: 0 auto !important;
      .block{
        width: 100% !important;
        display: flex !important;
        flex-wrap: wrap !important;
        // justify-content: space-evenly;
      }
    }
    .main-block{
      width: 172px !important;
      height: 196px !important;
      margin: 0 !important;
      // margin-left:10px !important ;
      img{
        height: 119px !important;
      }
      .title{
        font-size: 14px !important;
        padding:10px 7px 0 7px !important;
        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .main-block:nth-child(2n){
      margin-left:10px !important ;
    }
  }
  .ViewMore{
    margin-bottom:30px !important;
    text-align: center !important;
    div{
      margin-top:0 !important;
      font-size: 14px !important;
    }
    img{
      width: 11px !important;
      height: 11px !important;
      margin-top: -7px !important;
    }
  }
}

</style>
